import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function ServingCustomerLoyalty () {
  const shareTitle = 'Serving up customer loyalty with subscriptions and rapid delivery.'
  const articleLink = 'https://boldcommerce.com/case-studies/kettlemans-bagel'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Serving up customer loyalty with subscriptions and rapid delivery.</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>March 1, 2022</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <a href={articleLink} target="_blank" rel="noreferrer"><img src="/img/press/bold-kettlemans.jpg" alt="Kettlemans bagels" /></a>
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel="noreferrer">Bold Commerce</a></div>
                <p>Buckley and Wahab struggled to find the right family of subscription partners to meet the exacting standards set for their memorable restaurant experience.</p>

                <p>That’s when Buckley’s phone rang. It was Alok Ahuja, CEO &amp; Co-Founder of Trexity, a rapid delivery service with a network of drivers in Ottawa and Toronto. “What set Trexity apart from the competitors was their driver network. Not to mention the fact that their drivers are courteous and professional,” says Buckley.</p>

                <p>With Trexity, Kettlemans Bagel is able to ensure oven-to-door delivery faster than Amazon, and with better guest experience. Trexity allows both Kettlemans Bagel and their subscription customers to track their deliveries in real time.</p>
                <a href={articleLink} target="_blank" rel="noreferrer">Read more at Bold Commerce</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/helping-local-businesses-deliver" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Local company Trexity is helping Ottawa businesses deliver 1,000 packages a day to residents.</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
